<template>
  <div>
    <!--        CLICK MAIN GIF-->
    <div v-if="gif" class="fixed inset-0 bg-black z-50 flex justify-center items-center">
      <img @click="gif = false" class="w-full h-auto md:h-full cursor-pointer "
           src="/Lola_Explainer23_V1%20(2).gif" alt="">
    </div>


    <div v-if="!gif" :class="{ blurred: isBlurred }" class="fixed bottom-4 left-4 z-50 right-4">
      <div class="md:flex grid grid-cols-6">
        <div class="md:w-1/8 flex items-center justify-center">
          <img class="md:w-2/3 w-8 h-full" src="/logo2.svg" alt="">
        </div>
        <div class="border-l-2 border-white ml-4 pl-4 md:w-auto col-span-5">
          <Footer @social-modal-opened="handleSocialModalOpened"
                  @imprint-modal-opened="handleImprintModalOpened"
                  @social-modal-closed="handleSocialModalClosed"
                  @imprint-modal-closed="handleImprintModalClosed" @contactClick="contactModal = true"></Footer>
        </div>
      </div>
    </div>


    <!--    CONTAINER MAIN FRAME-->
    <div style="max-height: 100vh" class="relative flex justify-center items-start">

      <!--      MOBILE MENU-->
      <!--      WORK -->
      <div

          class="md:hidden w-full h-auto bg-black bg-opacity-30 z-40 absolute top-0 left-0  right-0 flex justify-between items-center px-4 py-4">
        <div @click="$router.push('/new')">
          <img class="w-12" src="/logo_new.svg" alt="">
        </div>
        <div @click="mobileMenu = !mobileMenu">
          <img class="w-8" src="/burger.svg" alt="">
        </div>
        <div v-if="mobileMenu" class="fixed inset-0" @click="mobileMenu = false">
        </div>
        <div v-if="mobileMenu" class="absolute md:hidden lef-0 right-0 top-full bg-black w-full py-4 px-4 flex flex-col">
          <div  :class="'order-' + menuOrder.Work" class="z-40 py-4">
            <p @click="allWork = true; corporateCategory = false; campaignCategory = false; mobileMenu = false"
               :class="{'text-orange': allWork || hoverFilm}" class="menu md:text-5xl hover:translate-x-10"
               id="tester">
              WORK</p>
          </div>
          <!-- MENÜPUNKT Corporate Mobile --_> STUDIO -->
          <div  :class="'order-' + menuOrder.Studio"
              class="z-40 py-4">
            <p @click="corporateCategory = true; allWork = false; campaignCategory = false; mobileMenu = false"
               :class="{'text-orange': corporateCategory || corporateHover}"
               class="menu md:text-5xl hover:translate-x-10 text-left"
               id="tester">
              STUDIO</p>
          </div>
          <!-- MENÜPUNKT Campaign Mobile  culture ---->
          <div  :class="'order-' + menuOrder.Culture"
              class="z-40 py-4">
            <p @click="campaignCategory = true; corporateCategory = false; allWork = false; mobileMenu = false"
               :class="{'text-orange': campaignCategory || campaignHover}" class="menu md:text-5xl hover:translate-x-10"
               id="tester">
              Culture</p>
          </div>


          <!-- MENÜPUNKT JONBS ---->
          <div  :class="'order-' + menuOrder.Jobs"
              class="z-40 py-4">
            <p @click="job.jobModal = true; mobileMenu = false"
               :class="{'text-orange': campaignCategory || campaignHover}" class="menu md:text-5xl hover:translate-x-10"
               id="tester">
              JOBS</p>
          </div>


          <!--          JOBS MENU -->

          <!--          <div>-->
          <!--            <Footer></Footer>-->
          <!--          </div>-->
        </div>
      </div>
      <!--     END MOBILE MENU-->

      <!--MODAL STUDIO IST CORPORATE CATEGORY-->
      <transition name="slide">
        <modal @close="corporateCategory = false" v-if="corporateCategory">
          <div class="text-right text-white text-base normal-case	md:w-2/3 flex flex-col items-end" v-html="studio"
          >
          </div>
        </modal>
      </transition>


      <!--      Campaign Category MODAL is CULTURE MODAL-->
      <transition name="slide">
        <modal @close="campaignCategory = false" v-if="campaignCategory">
          <div class="text-right text-white text-base normal-case	md:w-2/3 flex flex-col items-end" v-html="culture"
          >
          </div>
        </modal>
      </transition>


      <!--      JOB MODAL-->
      <transition name="slide">
        <modal @close="job.jobModal = false" v-if="job.jobModal">
          <div class="text-right text-white text-base normal-case	md:w-2/3 flex flex-col items-end"
          >
            <div v-for="stelle in job.jobs" :key="stelle.Headline">
              <div>
                <h2>{{ stelle.attributes.Headline }}</h2>
                <div v-html="stelle.attributes.Description"></div>
              </div>
            </div>
          </div>
        </modal>
      </transition>

      <!--      DESKTOP-->

      <!--      LOGO + FOOTER-->


      <!--      MENU PUNKTE DESKTOP MD-->
      <div class="z-40 w-full h-full flex flex-col items-end uppercase mr-8 mt-8">
        <!-- MENÜPUNKT WORK -->
        <div  :class="'order-' + menuOrder.Work" @mouseover="showFilm(); hoverFilm = true"
             @mouseleave="hideTest(); hoverFilm = false"
             class="z-40 hidden md:block py-1">
          <p @click="allWork = true; corporateCategory = false; campaignCategory = false;"
             :class="{'text-orange': allWork, 'text-orange': hoverFilm}"
             class="menu md:text-9xl hover:translate-x-10 text-right"
             id="tester">
            WORK</p>
          <div v-if="projects" v-show="test" class=" w-full min-w-max">
            <div class="hidden md:block">
              <div @click="filmModal(work)" @mouseover="bgChange(work.file)"
                   @mouseleave="bgChange(mainBG)" class="menu py-1"
                   v-for="(work) in allWorkMenu" :key="work.name">
                <p v-if="work.menu === true"
                   class="text-white text-2xl hover:text-orange hover:italic text-right">
                  {{ work.name }}
                </p>
              </div>
              <p class="text-primary py-1 text-2xl hover:text-white hover:italic cursor-pointer text-right"
                 @click="allWork = true; corporateCategory = false; campaignCategory = false;">MORE</p>
            </div>
          </div>
        </div>


        <!--        &lt;!&ndash;      Menüpunkt CREW&ndash;&gt;-->
        <!--        <div @mouseenter="hoverCrew = true" :class="{'text-orange': hoverCrew}" @mouseleave="hoverCrew = false"-->
        <!--             class="z-40 h-auto mx-0 hidden md:block py-1">-->
        <!--          <div-->
        <!--              class="menu md:text-9xl hover:translate-x-10 text-right">CREW-->
        <!--          </div>-->

        <!--        </div>-->
        <!--        <transition name="slide">-->
        <!--          <Employees @enter="hoverCrew = true" @leave="hoverCrew = false" v-show="hoverCrew"-->
        <!--                     :employees="working.employees"></Employees>-->
        <!--        </transition>-->


        <!--        Menüpunkt STUDIO-->
        <div  @mouseenter="hoverStudio = true" :class="[hoverStudio ? 'text-orange' : '', 'order-' + menuOrder.Studio]"
             @mouseleave="hoverStudio = false"
             class="z-40 h-auto mx-0 hidden py-1 md:flex flex-col items-end">
          <div class="menu md:text-9xl hover:translate-x-10 text-right">
            STUDIO
          </div>
          <transition name="slide">
            <div class="text-right text-white text-base normal-case	md:w-2/3 flex flex-col items-end" v-html="studio"
                 v-show="hoverStudio">
            </div>
          </transition>
        </div>


        <!--        Menüpunkt Culture-->
        <div @mouseenter="hoverCulture = true;" :class="[hoverCulture ? 'text-orange' : '', 'order-' + menuOrder.Culture]"
             @mouseleave="hoverCulture = false;"
             class="z-40 h-auto mx-0 hidden py-1 md:flex flex-col items-end">
          <div class="menu md:text-9xl hover:translate-x-10 text-right">
            CULTURE
          </div>
          <transition name="slide">
            <div class="text-right text-white text-base normal-case	md:w-2/3 flex flex-col items-end" v-html="culture"
                 v-show="hoverCulture">
            </div>
          </transition>
        </div>
        <!--        Menüpunkt Jobs-->
        <div @mouseenter="hoverJobs = true;" :class="[hoverJobs ? 'text-orange' : '', 'order-' + menuOrder.Jobs]"
             @mouseleave="hoverJobs = false;"
             class="z-40 h-auto mx-0 hidden py-1 md:flex flex-col items-end">
          <div class="menu md:text-9xl hover:translate-x-10 text-right">
            JOBS
          </div>


          <transition name="slide">
            <div @mouseenter="hoverJobs = true" @mouseleave="hoverJobs = false;"
                 class="flex flex-col items-end w-2/3 text-right" v-if="hoverJobs">
              <div v-for="stelle in job.jobs" :key="stelle.Headline">
                <div>
                  <h2>{{ stelle.attributes.Headline }}</h2>
                  <div v-html="stelle.attributes.Description"></div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <!--  BLACK BACKGROUND ABSOLUTE  -->
      <div class="fixed inset-0 flex flex-col justify-center items-center bg-black fixed inset-0"></div>


      <!-- BG VIDEO-->
      <div v-show="!modalFilm" class="fixed inset-0 z-auto" id="bg">
        <transition name="fadeVideo">
          <video v-if="screenWidth > 600" v-show="videoActive" class="object-cover w-full h-full" muted autoplay loop
                 id="bgVideo"
                 :src="mainBG"></video>
          <img class="object-cover w-full h-full" v-else :src="mainGif" alt="">
        </transition>
        <!-- BG VIDEO-->
      </div>

      <!-- MODAL ALLE FILME-->

      <transition name="fade">
        <!--        <div v-if="allWork"-->
        <!--             class="fixed top-0 left-0 right-0 bottom-0 flex items-center bg-black bg-opacity-90 flex flex-col overflow-scroll z-40">-->
        <!--          <div class="mt-24 md:mt-16">-->
        <!--            <div>-->
        <!--              <img @click="allWork = false" class="w-1/6 mx-auto cursor-pointer" src="/logo_new.svg" alt="">-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="grid md:grid-cols-4 p-16 gap-8">-->
        <!--            <div class="cursor-pointer" v-for="project in all" :key="project.name">-->
        <!--              <div class="aspect-w-4 aspect-h-3" @click="filmModal(project)">-->
        <!--                <img class="w-full h-full object-cover" :src="project.thumbnail" alt="">-->
        <!--              </div>-->
        <!--              <p class="text-primary text-base mt-2" @click="project.show = true">{{ project.name }}</p>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <modal @close="allWork = false" v-if="allWork">
          <div class="grid md:grid-cols-2 p-16 gap-8">
            <div class="cursor-pointer" v-for="project in all" :key="project.name">
              <div class="aspect-w-4 aspect-h-3" @click="filmModal(project)">
                <img class="w-full h-full object-cover" :src="project.thumbnail" alt="">
              </div>
              <p class="text-primary text-base mt-2" @click="project.show = true">{{ project.name }}</p>
            </div>
          </div>
        </modal>
      </transition>


      <!-- MODAL Contact FINAL OHNE COMPOIN-->

      <transition name="fade">
        <modal @close="contactModal = false" v-if="contactModal"
               class="">
          <div class="mt-24 md:mt-16">
            <div>
              <!--              <img @click="contactModal = false" class="w-16 mx-auto cursor-pointer" src="/logo_new.svg" alt="">-->
            </div>
          </div>
          <div class="container">
            <div class="text-base" v-html="content"></div>
          </div>
        </modal>
      </transition>


      <!-- MODAL EINZELNER FILM-->
      <modal @close="modalFilm = false" v-if="modalFilm">
        <video v-if="modalFilm" class="w-full mx-auto mb-4" preload="auto" controls
               :poster="selectedFilm.thumbnail" :src="selectedFilm.file"></video>
        <h1 class="text-left mb-8">{{ selectedFilm.name }}</h1>
        <div class="py-2" v-html="selectedFilm.description"></div>
      </modal>


      <!-- MODAL CONTACT -->
      <modal @close="modalContact = false" v-show="modalContact">
        <div class="container mx-auto p-8 md:p-32">
          <div class="mb-8" @click="modalFilm = false">
            <img class="w-1/4 mx-auto cursor-pointer" src="/logo_orange.png" alt="">
          </div>
          <div class="grid md:grid-cols-2 gap-32">
            <div>
              INFO<br><br>
              We produce films for TV + online + every screen you can imagine.
              <br><br>
              Actually, we just follow our passion to use our imagination + experience to visualize good ideas.
              <br><br>
              If you can watch it, we produce it.
              <br><br>
              We are a full service film agency.
              <br><br>
              sincerely,
              <br><br>
              Lola
            </div>
            <div class="w-full">
              <div class="w-full h-1 bg-white mb-8"></div>
              CONTACT<br><br>
              Email: hello@watchlola.de
              <br><br>
              Phone: +49 89 54 88 0970
              <br><br>
              Address: Baaderstraße 34, 80469 Munich – GER
              <br><br>
              <div class="w-full h-1 bg-white my-8"></div>
              OPENING HOURS<br>
              Mo – Fr: 10 – 6
              + on appointment
              <br><br>
              Feel free to come by we offer excellent coffee.
            </div>
          </div>
        </div>
      </modal>

      <!-- Social Modal -->
      <transition name="fade">
        <modal @close="modalSocial = false;" v-if="modalSocial"
               class="">
          <div v-html="contentSocial" class="container text-white">

          </div>
        </modal>
      </transition>

      <!--    Imprint Modal-->
      <transition name="fade">
        <modal @close="modalImprint = false"
               class=""
               v-if="modalImprint">
          <div class="">
            <div class="w-full text-left" v-html="this.contentImprint"></div>
          </div>
        </modal>
      </transition>

    </div>
  </div>
</template>

<script>
import api from '@/util/api'
import Modal from "@/components/Modal.vue";
import Footer from "@/components/Footer.vue";


export default {
  name: "New",
  components: {Footer, Modal},
  data() {
    return {
      work: [],
      gif: true,
      // isBlurred: false,
      mobileMenu: false,
      test: false,
      corporate: false,
      campaign: false,
      contact: false,
      allWork: false,
      hoverFilm: false,
      corporateCategory: false,
      corporateHover: false,
      campaignCategory: false,
      campaignHover: false,
      mainBG: 'https://lola-strapi.s3.eu-central-1.amazonaws.com/video_b1364fe4e2.mp4',
      mainGif: '',
      // MODALS
      modalFilm: false,
      modalCorporate: false,
      modalCampaign: false,
      modalContact: false,
      modalSocial: false,
      // MODALS END
      selectedFilm: {},
      selectedCorporate: {},
      selectedCampaign: {},
      videoActive: true,
      baseUrl: process.env.VUE_APP_API_ENDPOINT,
      hoverCrew: false,
      working: {
        crewAccordion: false,
        employees: [],
      },
      modalEmployee: false,
      hoverCulture: false,
      hoverStudio: false,
      culture: '',
      hoverJobs: false,
      job: {
        jobs: null,
        jobModal: false,
        jobBg: null,
        jobName: null
      },
      contactModal: false,
      content: '',
      studio: '',
      modalImprint: false,
      contentSocial: '',
      contentImprint: '',
      menuOrder: {}
    }
  },
  computed: {
    isBlurred() {
      if (this.allWork) {
        return true
      } else if (this.hoverFilm) {
        return true
      } else if (this.hoverStudio) {
        return true
      } else if (this.hoverCulture) {
        return true
      } else if (this.hoverJobs) {
        return true
      } else if (this.contactModal) {
        return true
      } else if (this.modalImprint) {
        return true
      } else if (this.modalSocial) {
        return true
      } else if (this.job.jobModal){
        return true
      } else if (this.campaignCategory){
        return true
      } else if (this.corporateCategory){
        return true
      } else {
        return false
      }
    },
    all() {
      return this.$store.state.film
    },
    allWorkMenu() {
      return this.$store.state.film.filter(film => film.menu);
    },
    projects() {
      return this.$store.getters.menuFilm
    },
    filmList() {
      return this.$store.getters.category('film')
    },
    filmListMenu() {
      let list = this.filmList.filter(film => {
        return film.menu === true;
      })
      return list
    },
    corporateList() {
      return this.$store.getters.category('corporate')
    },
    corporateListMenu() {
      let list = this.corporateList.filter(film => {
        return film.menu === true;
      })
      return list
    },
    campaignList() {
      return this.$store.getters.category('campaign')
    },
    campaignListMenu() {
      let list = this.campaignList.filter(film => {
        return film.menu === true;
      })
      return list
    },
    screenWidth() {
      return window.innerWidth
    }
  },
  methods: {
    handleSocialModalOpened() {
      // Handle the event when the social modal is opened

      this.modalSocial = true
      // this.contentSocial.instagram = payload.instagram
      // this.contentSocial.linkedin = payload.linkedin
      // this.contentSocial.vimeo = payload.vimeo
    },
    handleImprintModalOpened(payload) {
      // Handle the event when the imprint modal is opened

      this.modalImprint = true
      this.contentImprint = payload.content
    },
    filmModal(project) {
      this.modalFilm = true
      this.selectedFilm = project
    },
    corporateModal(project) {
      this.modalCorporate = true
      this.selectedCorporate = project
    },
    campaignModal(project) {
      this.modalCampaign = true
      this.selectedCampaign = project
    },
    showFilm() {
      this.test = true;
      // this.isBlurred = true;
      /*document.getElementById('tester').style.transform = "scale(0.9, 0.9)"*/
    },
    blurFooter() {
      this.isBlurred = true;
    },
    unblurFooter() {
      this.isBlurred = false;
    },
    showCorporate() {
      this.corporate = true;
      /*document.getElementById('tester').style.transform = "scale(0.9, 0.9)"*/
    },
    showCampaign() {
      this.campaign = true;
      /*document.getElementById('tester').style.transform = "scale(0.9, 0.9)"*/
    },
    showContact() {
      this.contact = true;
    },
    hideContact() {
      this.contact = false;
    },
    hideTest() {
      this.test = false;
      this.isBlurred = false;
      /*document.getElementById('tester').style.transform = "scale(1, 1)"*/
    },
    hideCorporate() {
      this.corporate = false;
      /*document.getElementById('tester').style.transform = "scale(1, 1)"*/
    },
    hideCampaign() {
      this.campaign = false;
      /*document.getElementById('tester').style.transform = "scale(1, 1)"*/
    },
    bgChange(b) {
      this.videoActive = false
      document.getElementById('bgVideo').src = b + '#t=20'
      this.videoActive = true
    }
  },
  async mounted() {
    await this.$store.dispatch('GETFILM')
    await api.get('/background?populate=*').then(res => {
      this.mainBG = res.data.data.attributes.Background_Desktop.data.attributes.url
      this.mainGif = res.data.data.attributes.Background_Mobile.data.attributes.url
    })
    await api.get('/employees?populate=*').then(res => {
      this.working.employees = res.data.data
    })
    await api.get('/culture?populate=*').then(res => {
      this.culture = res.data.data.attributes.Content
    })
        .catch((err) => {
          console.log(err)
        })
    await api.get('/studio?populate=*').then(res => {
      this.studio = res.data.data.attributes.Content
    })
        .catch((err) => {
          console.log(err)
        })

    await api.get('/jobs').then(res => {
      this.job.jobs = res.data.data
    })
    await api.get('/contact?populate=*').then(res => {
      this.content = res.data.data.attributes.Content
    })
    await api.get('/site-social').then(res => {
      this.contentSocial = res.data.data.attributes.Content
    })
    await api.get('/menu').then(res => {
      this.menuOrder = res.data.data.attributes
    })
  }
}
</script>

<style scoped>
.menu {
  cursor: pointer;
}

.box {
  border: solid;
}

.fade-enter-active, .fade-leave-active {
  transition: all .4s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.fadeVideo-enter-active, .fade-leave-active {
  transition: opacity .9s ease;
}

.fadeVideo-enter, .fadeVideo-leave-to {
  opacity: 0;
}

.fulldesc-enter-active, .fulldesc-leave-active {
  transition: all .9s ease;
}

.fulldesc-enter, .fulldesc-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.blurred {
  filter: blur(5px);
}
</style>